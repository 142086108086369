import React, { useEffect, useRef, useState } from 'react';
import MonitorAnimation from '../../components/MonitorAnimation/MonitorAnimation';
import StepBar from '../../components/Stepbar/Stepbar';
import YelowAndGreenBoxes from '../../components/YelowAndGreenBoxes/YelowAndGreenBoxes';
import ChatBox from '../../components/ChatBox/ChatBox';
import ChatBoxSecond from '../../components/ChatBoxSecond/ChatBoxSecond';
import ReviewCard from '../../components/ReviewCard/ReviewCard';
import Reviews from '../../components/Reviews/Reviews';
import HeroSection from '../../components/HeroSection/HeroSection';
import SocialFollow from '../../components/SocialFollow/SocialFollow';
import Marquee from '../../components/Marquee/Marquee';
import Timeline from '../../components/TimeLine/TimeLine';
import Team from '../../components/Team/Team';
import CaseStudies from '../../components/CaseStudies/CaseStudies';
import SubHeader from '../../components/SubHeader/SubHeader';
import ParallaxComponent from '../../components/ParallaxComponent/ParallaxComponent';
import TiktokAuth from '../../components/TiktokAuth/TiktokAuth';
import Technologies from '../../components/Technologies/Technologies';
import DevelopmentSteps from '../../components/DevelopmentSteps/DevelopmentSteps';
import UsernameSelection from '../../components/UsernameSelection/UsernameSelection';
import '../../App.css';
import WhoAreWe from '../../components/WhoAreWe/WhoAreWe';
import { useLanguage } from '../../lang';
import { Helmet } from 'react-helmet';

export default function MainPage({language}) {
	const { switchLanguage } = useLanguage();

	const content = {
	  en: {
		title: "What you get at AKZY Studio",
		popularConcept: "Popular Concept",
		viral: "Viral",
		easyToPlay: "Easy to Play",
		whatYouGet: [ 
			
			["Popular Concept", "Your game is trending and has broad appeal." ], 
			['Viral', 'Your game is designed to be shared and quickly capture the attention of a large audience.'], 
			['Copyright-Free', 'Your game is free from licensing complications.'],
			["Easy to Play", 'Your game is intuitive for players, ensuring a seamless gaming experience.'],
			['Web3 Integration', 'Your game can incorporate TON-based transactions.'],
			['Flexible Development Platforms', 'We use primarily HTML5 native games, but we also support Unity development.']
	
			
			],

		
		nav: ['About', 'Our Cases', 'Contact'],
	  },
	  ru: {
		title: "Что вы получаете в AKZY Studio",
		

		viral: "Вирусная",
		easyToPlay: "Простота игры",
		whatYouGet: [ 
			
		["Популярная Концепция", "Ваша игра в тренде и имеет широкую популярность." ], 
		['Вирусность', 'Ваша игра рассчитана на то, что ею будут делиться и она быстро привлечет внимание большой аудитории.'], 
		['Авторские права', 'Ваша игра свободна от лицензионных сложностей.'],
		["Простота игры", 'Ваша игра интуитивно понятна игрокам, обеспечивая вовлекающий игровой процесс.'],
		['Интеграция Web3', 'Наша команда подключит любые Web3 инструменты на основе популярных блокчейнов , таких как TON/ETH/SOL'],
		['Выбор игрового движка', 'Мы готовы произвести разработку как на основе HTML5 так и на игровых движках по типу Unity']

		
		],
		
		nav: ['О нас', 'Кейсы', 'Связаться'],
	  },
	};
	return (
		<div className="container">
			  	<Helmet>
					<html lang={language} />
					<title>{language === 'en' ? 'AKZY Studio' : 'AKZY Studio'}</title>
					<link rel="alternate" href="/en/" hreflang="en" />
					<link rel="alternate" href="/ru/" hreflang="ru" />
				</Helmet>
			{/*  <TiktokAuth/> */}
			<header className="header">
				<div className="logo"></div>
				<nav className="nav">
				<a href="#about">{content[language].nav[0]}</a>
				<a href="#roadmap">{content[language].nav[1]}</a>
				<a href="#contactus">{content[language].nav[2]}</a>
				{
					language != 'en' ? <a onClick={() => switchLanguage('en')}><img style={{width:'30px', paddingTop: '5px'}} src='/icons/icons8-great-britain-48.png'/></a> : <a onClick={() => switchLanguage('ru')}>  <img style={{width:'30px', paddingTop: '5px'}} src='/icons/icons8-russia-48.png'/>  </a>
				}
			
				</nav>
			</header>

			<main className="main-content">
				<SubHeader language={language} />
				<WhoAreWe language={language} />

				<div className="checklist-container">
					<h1>{content[language].title}</h1>
					<div className="checklist-items">
					
						{content[language].whatYouGet.map((item,index)=>(
							<div className="checklist-item" key={index}>
							<span className="check-icon">✔</span>
							<div className="check-text">
								<h2 className="typing-effect">{item[0]}</h2>
								<p>{item[1]}</p>
							</div>
							</div>

						))}
						
					</div>
				</div>

				<YelowAndGreenBoxes language={language}  />

				<button className="askmanager">
					<a href="#contactus" className=" no-style-link color-link">
					{language != 'ru' ?	"[ASK AKZY MANAGER]" : '[СПРОСИТЕ МЕНЕДЖЕРА AKZY]' }
					</a>
				</button>
				<ChatBoxSecond language={language} />

				<div style={{ marginTop: '100px' }}></div>
				<DevelopmentSteps  language={language}/>
				<Technologies  language={language} />
				<div style={{ marginTop: '100px' }}></div>

				{/* <Timeline/> */}
				<Team  language={language} />
				<CaseStudies  language={language} />
				<ParallaxComponent language={language}  />
				{/*  <Reviews/>  */}
				{/*    <UsernameSelection/> */}
			</main>
			<div style={{ marginTop: '100px' }}></div>
			<SocialFollow language={language} />
			<Marquee />
		</div>
	);
}
